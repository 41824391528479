import React, { FC, useEffect, useState } from "react";
import { Alert, Button, Col, Divider, Form, Input, notification, Radio, Row, Select, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import {
  ITerminalPRMRInfoAPAppcfg,
  saveTerminalPrmInfoAPAppcfg,
  terminalPrmInfoAPAppcfg,
} from "../../../services/terminalPrm";

interface IProps {
  terminalId: string;
}

export const TerminalPrmAPAppcfg: FC<IProps> = ({ terminalId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoAPAppcfg>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  useEffect(
    function () {
      if (terminalId !== "") {
        terminalPrmInfoAPAppcfg({ terminal_id: terminalId }).then((res) => {
          if (res.status === 0) {
            setFormValues(res.data);
            form.setFieldsValue(res.data);
          }
        });
      }
    },
    [terminalId]
  );
  const saveForm = (values) => {
    if (formValues === undefined) {
      return;
    }
    setBtnLoading(true);
    saveTerminalPrmInfoAPAppcfg({ id: formValues["id"], terminalId: terminalId, ap: values["ap"] })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const customAlert = (text: string) => {
    return (
      <>
        <Alert
          style={{ marginBottom: "20px" }}
          message={
            <div style={{ height: "40px", lineHeight: "40px", fontSize: "18px", fontWeight: "bold" }}>{text}</div>
          }
          type="info"
        />
      </>
    );
  };
  return (
    <Row gutter={10} style={{ marginTop: "20px" }} hidden={terminalId === ""}>
      <div className="form-content-container-content">
        <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
          <Tabs defaultActiveKey="AppUI" tabPosition="left">
            <Tabs.TabPane tab="AppUI" key="AppUI" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.IdleNavigationBarFlag")} name={["ap", "AppUI", "IdleNavigationBarFlag"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SaleIdle")} name={["ap", "AppUI", "SaleIdle"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.PollCardTimeout")} name={["ap", "AppUI", "PollCardTimeout"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.UITimeout")} name={["ap", "AppUI", "UITimeout"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.IdleFragmentType")} name={["ap", "AppUI", "IdleFragmentType"]}>
                    <Select>
                      <Select.Option value="TxnType">TxnType</Select.Option>
                      <Select.Option value="PaymentType">PaymentType</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SignatureType")} name={["ap", "AppUI", "SignatureType"]}>
                    <Select
                      options={[
                        { value: "vertical", label: "Vertical" },
                        { value: "horizontal", label: "Horizontal" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TrainingModeY")} name={["ap", "AppUI", "TrainingModeY"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TrainingModeFontSize")} name={["ap", "AppUI", "TrainingModeFontSize"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.receipts")} key="Receipts" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.CustomerReceiptFlag")} name={["ap", "Receipts", "CustomerReceiptFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MerchantReceiptFlag")} name={["ap", "Receipts", "MerchantReceiptFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MerchantReceiptSignatureFlag")}
                    name={["ap", "Receipts", "MerchantReceiptSignatureFlag"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptHeaderImage")} name={["ap", "Receipts", "ReceiptHeaderImage"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptHeader1")} name={["ap", "Receipts", "ReceiptHeader1"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptHeader2")} name={["ap", "Receipts", "ReceiptHeader2"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptHeader3")} name={["ap", "Receipts", "ReceiptHeader3"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptFooterImage")} name={["ap", "Receipts", "ReceiptFooterImage"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptFooter1")} name={["ap", "Receipts", "ReceiptFooter1"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptFooter2")} name={["ap", "Receipts", "ReceiptFooter2"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptFooter3")} name={["ap", "Receipts", "ReceiptFooter3"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.PrinterHeatLevel")} name={["ap", "Receipts", "PrinterHeatLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReceiptFontName")} name={["ap", "Receipts", "ReceiptFontName"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.tms")} key="Tms" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.TmsType")} name={["ap", "Tms", "TmsType"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TmsFlag")} name={["ap", "Tms", "TmsFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TmsBootUpdateFlag")} name={["ap", "Tms", "TmsBootUpdateFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TmsSettleUpdateFlag")} name={["ap", "Tms", "TmsSettleUpdateFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TmsAutoUpdateFlag")} name={["ap", "Tms", "TmsAutoUpdateFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TmsAutoUpdateTime")} name={["ap", "Tms", "TmsAutoUpdateTime"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TmsLastTime")} name={["ap", "Tms", "TmsLastTime"]}>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.app_util")} key="AppUtil" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.PowerSuspendFlag")} name={["ap", "AppUtil", "PowerSuspendFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SuspendTimeout")} name={["ap", "AppUtil", "SuspendTimeout"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.WakeMode")}
                    name={["ap", "AppUtil", "WakeMode"]}
                    normalize={(value) => Number(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.OperatorPwd")} name={["ap", "AppUtil", "OperatorPwd"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MerchantPwd")} name={["ap", "AppUtil", "MerchantPwd"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReferralPwd")} name={["ap", "AppUtil", "ReferralPwd"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ManualEnable")} name={["ap", "AppUtil", "ManualEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.BarcodeScannerCode")} name={["ap", "AppUtil", "BarcodeScannerCode"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Currency")} name={["ap", "AppUtil", "Currency"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.CountryCode")} name={["ap", "AppUtil", "CountryCode"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.CurrencySymbol")} name={["ap", "AppUtil", "CurrencySymbol"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AutoSettleLevelAppUtil")} name={["ap", "AppUtil", "AutoSettleLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AutoSettleTime")} name={["ap", "AppUtil", "AutoSettleTime"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.LastAutoSettleTime")} name={["ap", "AppUtil", "LastAutoSettleTime"]}>
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SignatureTimeout")} name={["ap", "AppUtil", "SignatureTimeout"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MenuPwd")} name={["ap", "AppUtil", "MenuPwd"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.BacklightLevel")} name={["ap", "AppUtil", "BacklightLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SqliteDataMaxDay")} name={["ap", "AppUtil", "SqliteDataMaxDay"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AutoResetFlag")} name={["ap", "AppUtil", "AutoResetFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AutoResetTime")} name={["ap", "AppUtil", "AutoResetTime"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AutoSettleType")} name={["ap", "AppUtil", "AutoSettleType"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.BarcodeScannerType")} name={["ap", "AppUtil", "BarcodeScannerType"]}>
                    <Select
                      options={[
                        { value: "default", label: "Default" },
                        { value: "honeywell", label: "Honeywell" },
                        { value: "barcode", label: "Barcode" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AuthPhone")} name={["ap", "AppUtil", "AuthPhone"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.FixPhone")} name={["ap", "AppUtil", "FixPhone"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ServicePhone")} name={["ap", "AppUtil", "ServicePhone"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.RequestNetwork")} name={["ap", "AppUtil", "RequestNetwork"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.RequestNetworkType")} name={["ap", "AppUtil", "RequestNetworkType"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.app_buttons")} key="AppButtons" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.ReprintEnableLevel")} name={["ap", "AppButtons", "ReprintEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MenuEnableLevel")} name={["ap", "AppButtons", "MenuEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SettingEnableLevel")} name={["ap", "AppButtons", "SettingEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.PrinterEnableLevel")} name={["ap", "AppButtons", "PrinterEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.PasswordEnableLevel")} name={["ap", "AppButtons", "PasswordEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AboutEnableLevel")} name={["ap", "AppButtons", "AboutEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.LastEnableLevel")} name={["ap", "AppButtons", "LastEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TrainingEnableLevel")} name={["ap", "AppButtons", "TrainingEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.UpdateEnableLevel")} name={["ap", "AppButtons", "UpdateEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ExitApEnableLevel")} name={["ap", "AppButtons", "ExitApEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ResetDefaultCFGEnableLevel")}
                    name={["ap", "AppButtons", "ResetDefaultCFGEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AdditionEnableLevel")} name={["ap", "AppButtons", "AdditionEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.BatchEnableLevel")} name={["ap", "AppButtons", "BatchEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.FindEnableLevel")} name={["ap", "AppButtons", "FindEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.ReviewEnableLevel")} name={["ap", "AppButtons", "ReviewEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TotalEnableLevel")} name={["ap", "AppButtons", "TotalEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DccCurrencyDownload")} name={["ap", "AppButtons", "DccCurrencyDownload"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DccPycDownload")} name={["ap", "AppButtons", "DccPycDownload"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DccRateDownload")} name={["ap", "AppButtons", "DccRateDownload"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SaleEnableLevel")} name={["ap", "AppButtons", "SaleEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.VoidEnableLevel")} name={["ap", "AppButtons", "VoidEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.RefundEnableLevel")} name={["ap", "AppButtons", "RefundEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.OfflineSaleEnableLevel")}
                    name={["ap", "AppButtons", "OfflineSaleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TipEnableLevel")} name={["ap", "AppButtons", "TipEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AdjustEnableLevel")} name={["ap", "AppButtons", "AdjustEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ChkBalanceEnableLevel")}
                    name={["ap", "AppButtons", "ChkBalanceEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TopupEnableLevel")} name={["ap", "AppButtons", "TopupEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.PreAuthEnableLevel")} name={["ap", "AppButtons", "PreAuthEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.CaptureEnableLevel")} name={["ap", "AppButtons", "CaptureEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AuthVoidEnableLevel")} name={["ap", "AppButtons", "AuthVoidEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SettleEnableLevel")} name={["ap", "AppButtons", "SettleEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MoreTxnEnableLevel")} name={["ap", "AppButtons", "MoreTxnEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ChangeMasterKeyEnableLevel")}
                    name={["ap", "AppButtons", "ChangeMasterKeyEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ChangeWorkingKeyEnableLevel")}
                    name={["ap", "AppButtons", "ChangeWorkingKeyEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TopupVoidEnableLevel")} name={["ap", "AppButtons", "TopupVoidEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.VolumeEnableLevel")} name={["ap", "AppButtons", "VolumeEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.WifiEnableLevel")} name={["ap", "AppButtons", "WifiEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.PowerEnableLevel")} name={["ap", "AppButtons", "PowerEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.BatchDelete")} name={["ap", "AppButtons", "BatchDelete"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TutorialEnableLevel")} name={["ap", "AppButtons", "TutorialEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DownloadTutorialEnableLevel")}
                    name={["ap", "AppButtons", "DownloadTutorialEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MohistEnableLevel")} name={["ap", "AppButtons", "MohistEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SysVerEnableLevel")} name={["ap", "AppButtons", "SysVerEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.QueryEnableLevel")} name={["ap", "AppButtons", "QueryEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.cashier")} key="Cashier" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.CashierEnableLevel")} name={["ap", "Cashier", "CashierEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.CashierAddRmvEnableLevel")}
                    name={["ap", "Cashier", "CashierAddRmvEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.CashierViewEnableLevel")}
                    name={["ap", "Cashier", "CashierViewEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.CashierReportEnableLevel")}
                    name={["ap", "Cashier", "CashierReportEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.CashierClearEnableLevel")}
                    name={["ap", "Cashier", "CashierClearEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.TableNumberEnable")} name={["ap", "Cashier", "TableNumberEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.CashierEnable")} name={["ap", "Cashier", "CashierEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.PasswordSkip")} name={["ap", "Cashier", "PasswordSkip"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.debuglog")} key="Debuglog" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MenuDebugLogEnableLevel")}
                    name={["ap", "Debuglog", "MenuDebugLogEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugLogEnableLevel")} name={["ap", "Debuglog", "DebugLogEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPUploadEnableLevel")}
                    name={["ap", "Debuglog", "DebugFTPUploadEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugUSBExportEnableLevel")}
                    name={["ap", "Debuglog", "DebugUSBExportEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugLevel")} name={["ap", "Debuglog", "DebugLevel"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugFTPServerType")} name={["ap", "Debuglog", "DebugFTPServerType"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugFTPIP")} name={["ap", "Debuglog", "DebugFTPIP"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugFTPPort")} name={["ap", "Debuglog", "DebugFTPPort"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugFTPID")} name={["ap", "Debuglog", "DebugFTPID"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugFTPPassword")} name={["ap", "Debuglog", "DebugFTPPassword"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPServerFilePath")}
                    name={["ap", "Debuglog", "DebugFTPServerFilePath"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugFTPUploadEnable")} name={["ap", "Debuglog", "DebugFTPUploadEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPUploadTimingRegularFlag")}
                    name={["ap", "Debuglog", "DebugFTPUploadTimingRegularFlag"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPUploadTimingRegularSettleFlag")}
                    name={["ap", "Debuglog", "DebugFTPUploadTimingRegularSettleFlag"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPUploadTimingRegularTime")}
                    name={["ap", "Debuglog", "DebugFTPUploadTimingRegularTime"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPUploadOneTimeFlag")}
                    name={["ap", "Debuglog", "DebugFTPUploadOneTimeFlag"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DebugFTPUploadOneTime")} name={["ap", "Debuglog", "DebugFTPUploadOneTime"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPUploadOneTimeCheck")}
                    name={["ap", "Debuglog", "DebugFTPUploadOneTimeCheck"]}
                  >
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebugFTPUploadLastTime")}
                    name={["ap", "Debuglog", "DebugFTPUploadLastTime"]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.merchant_portal")} key="MerchantPortal" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.MerchantPortalFlag")} name={["ap", "MerchantPortal", "MerchantPortalFlag"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MerchantPortalSslType")}
                    name={["ap", "MerchantPortal", "MerchantPortalSslType"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MerchantPortalIp")} name={["ap", "MerchantPortal", "MerchantPortalIp"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MerchantPortalRootCA")}
                    name={["ap", "MerchantPortal", "MerchantPortalRootCA"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MerchantPortalIntermediateCA")}
                    name={["ap", "MerchantPortal", "MerchantPortalIntermediateCA"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MerchantPortalClientKey")}
                    name={["ap", "MerchantPortal", "MerchantPortalClientKey"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MerchantPortalClientCert")}
                    name={["ap", "MerchantPortal", "MerchantPortalClientCert"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MerchantPortalMaxStore")}
                    name={["ap", "MerchantPortal", "MerchantPortalMaxStore"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.MerchantPortalType")} name={["ap", "MerchantPortal", "MerchantPortalType"]}>
                    <Select
                      options={[
                        { label: "Tradeye", value: "Tradeye" },
                        {
                          label: "MerchantPortal",
                          value: "MerchantPortal",
                        },
                      ]}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.pos_service")} key="PosService" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.CmdInputsEnableLevel")} name={["ap", "PosService", "CmdInputsEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSServerEnable")} name={["ap", "PosService", "POSServerEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSServerIP")} name={["ap", "PosService", "POSServerIP"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSServerPort")} name={["ap", "PosService", "POSServerPort"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSConnectType")} name={["ap", "PosService", "POSConnectType"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSHeartBeatTiming")} name={["ap", "PosService", "POSHeartBeatTiming"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSConnectTimeout")} name={["ap", "PosService", "POSConnectTimeout"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSSslCrtName")} name={["ap", "PosService", "POSSslCrtName"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSSslKeyName")} name={["ap", "PosService", "POSSslKeyName"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.CAINFOList")} name={["ap", "PosService", "CAINFOList"]} extra={t('text.multiple_input')}>
                    <Select mode={"tags"} open={false} />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.POSRequestAckFlag")} name={["ap", "PosService", "POSRequestAckFlag"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Wifi" key="Wifi" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.WifiEnableLevel")} name={["ap", "Wifi", "WifiEnableLevel"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Enable")} name={["ap", "Wifi", "Enable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.DhcpEnable")} name={["ap", "Wifi", "DhcpEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Type")} name={["ap", "Wifi", "Type"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.SSID")} name={["ap", "Wifi", "SSID"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Password")} name={["ap", "Wifi", "Password"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.IpAddress")} name={["ap", "Wifi", "IpAddress"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Netmask")} name={["ap", "Wifi", "Netmask"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Gateway")} name={["ap", "Wifi", "Gateway"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Dns1")} name={["ap", "Wifi", "Dns1"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.Dns2")} name={["ap", "Wifi", "Dns2"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.sound")} key="Sound" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.MediaVolume")} name={["ap", "Sound", "MediaVolume"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.CallVolume")} name={["ap", "Sound", "CallVolume"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.AlarmVolume")} name={["ap", "Sound", "AlarmVolume"]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.NotificationVolume")} name={["ap", "Sound", "NotificationVolume"]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.payment_modules")} key="PaymentModules" forceRender={true}>
              {customAlert(t("title.credit"))}
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.CreditEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "CreditEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SaleEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "SaleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.AdjustEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "AdjustEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.TipsEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "TipsEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.RefundEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "RefundEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.VoidEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "VoidEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.PreauthEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "PreauthEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.Preauth_completeEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "Preauth_completeEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.Batch_uploadEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "Batch_uploadEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SettlementEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "SettlementEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.Offline_saleEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "Offline_saleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.MoreTxnEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "MoreTxnEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ManualTxnEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "ManualTxnEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.InstallmentEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "InstallmentEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.UnionPayEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "UnionPayEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.UnionPaySaleEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "UnionPaySaleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col flex="300px">
                  <Form.Item
                    label={t("form.UnionPayPreAuthEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "UnionPayPreAuthEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.UnionPayCaptureEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "UnionPayCaptureEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.UnionPayRefundEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "UnionPayRefundEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.UnionPayVoidEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "UnionPayVoidEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.UnionPayPreAuthVoidEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "UnionPayPreAuthVoidEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebitEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "DebitEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebitSaleEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "DebitSaleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebitRefundEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "DebitRefundEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DebitVoidEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "DebitVoidEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ModuleHandleReceipt")}
                    name={["ap", "PaymentModules", "Credit", "ModuleHandleReceipt"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.AutoSettleLevel")}
                    name={["ap", "PaymentModules", "Credit", "AutoSettleLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.AutoSettleTime")}
                    name={["ap", "PaymentModules", "Credit", "AutoSettleTime"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.LastAutoSettleTime")}
                    name={["ap", "PaymentModules", "Credit", "LastAutoSettleTime"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.PYCDownLoadLevel")}
                    name={["ap", "PaymentModules", "Credit", "PYCDownLoadLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.PYCDownLoadTime")}
                    name={["ap", "PaymentModules", "Credit", "PYCDownLoadTime"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.PYCDownLoadPeriod")}
                    name={["ap", "PaymentModules", "Credit", "PYCDownLoadPeriod"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.LastPYCDownLoadTime")}
                    name={["ap", "PaymentModules", "Credit", "LastPYCDownLoadTime"]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.LastPYCDownLoadSuccessful")}
                    name={["ap", "PaymentModules", "Credit", "LastPYCDownLoadSuccessful"]}
                  >
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SignaturePeriod")}
                    name={["ap", "PaymentModules", "Credit", "SignaturePeriod"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SignatureUploadLevel")}
                    name={["ap", "PaymentModules", "Credit", "SignatureUploadLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.LastSignatureUploadTime")}
                    name={["ap", "PaymentModules", "Credit", "LastSignatureUploadTime"]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SignatureUploadEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "SignatureUploadEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.TmsParamEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "TmsParamEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.DownloadTutorialEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "DownloadTutorialEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.PostEnableLevel")}
                    name={["ap", "PaymentModules", "Credit", "PostEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              {customAlert(t("title.e_ticket"))}
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ETicketEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "ETicketEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SaleEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "SaleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.TopupEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "TopupEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.CheckBalanceEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "CheckBalanceEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.TopupVoidEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "TopupVoidEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.RefundEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "RefundEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SettlementEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "SettlementEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.LogonEnableLevel")}
                    name={["ap", "PaymentModules", "ETicket", "LogonEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ModuleHandleReceipt")}
                    name={["ap", "PaymentModules", "ETicket", "ModuleHandleReceipt"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              {customAlert(t("title.e_wallet"))}
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.EWalletEnableLevel")}
                    name={["ap", "PaymentModules", "EWallet", "EWalletEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.LinePayEnableLevel")}
                    name={["ap", "PaymentModules", "EWallet", "LinePayEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SaleEnableLevel")}
                    name={["ap", "PaymentModules", "EWallet", "LinePay", "SaleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.ModuleHandleReceipt")}
                    name={["ap", "PaymentModules", "EWallet", "LinePay", "ModuleHandleReceipt"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                      label={t("form.SettleDetailFlag")}
                      name={["ap", "PaymentModules", "EWallet", "SettleDetailFlag"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              {customAlert("CyberSource")}
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.CyberSourceEnableLevel")}
                    name={["ap", "PaymentModules", "CyberSource", "CyberSourceEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SaleEnableLevel")}
                    name={["ap", "PaymentModules", "CyberSource", "SaleEnableLevel"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("title.slideshow")} key="Slideshow" forceRender={true}>
              <Row gutter={30}>
                <Col flex="300px">
                  <Form.Item label={t("form.SlideshowEnable")} name={["ap", "Slideshow", "SlideshowEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item
                    label={t("form.SlideshowIntervalSecond")}
                    name={["ap", "Slideshow", "SlideshowIntervalSecond"]}
                    normalize={(value) => Number(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col flex="300px">
                  <Form.Item label={t("form.VideoEnable")} name={["ap", "Slideshow", "VideoEnable"]}>
                    <Radio.Group>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Form>
        <Divider />
        <div>
          <Button
            type="primary"
            shape="round"
            style={{ width: "200px", float: "right" }}
            htmlType="submit"
            loading={btnLoading}
            onClick={() => form.submit()}
          >
            {t("button.save")}
          </Button>
        </div>
      </div>
    </Row>
  );
};
